<template>
    <div>
        <div class="load-filter-btn-wrapper">
            <a @click="removeHistory" class="mt-3 mr-3">
                Remove
            </a>
            <b-button
                :disabled="selectedRow === null"
                class="applyButton mt-2 mr-3"
                @click="loadHistory()"
            >
                Load
            </b-button>
        </div>
        <div class="filter-table-wrapper mt-3 flt-tbl ">
            <ejs-grid
                :dataSource="savedTableData"
                @rowSelected="dataSetRowSelected"
                @commandClick="gridRowData"
                :allowPaging="true"
                :pageSettings="pageSettings"
            >
                <e-columns>
                    <e-column
                        headerText="Edit"
                        title="Edit"
                        width="50"
                        :commands="editCommands"
                    ></e-column>
                    <e-column
                        field="CreationDate"
                        width="100"
                        headerText="Date Created"
                    ></e-column>
                    <e-column
                        field="Name"
                        width="100"
                        headerText="Name"
                    ></e-column>
                    <e-column
                        field="Description"
                        width="100"
                        headerText="Description"
                    ></e-column>
                    <e-column
                        field="DateLastUsed"
                        width="100"
                        headerText="Date Used"
                    ></e-column>
                </e-columns>
            </ejs-grid>
        </div>
        <EditFilterModal
            :filterName="filterName"
            :filterDescription="filterDescription"
            :filterCategory="filterCategory"
            :editUserPortalId="editUserPortalId"
            @deleteEditFilterDetails="deleteEditFilterDetails"
        ></EditFilterModal>
    </div>
</template>

<script>
import Vue from 'vue';
import { datasetService } from '../../services/dataset.service';
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import {
    GridPlugin,
    Sort,
    Page,
    Filter,
    Freeze,
    CommandColumn,
    Search,
    Resize,
    Toolbar,
    Grid,
    ExcelExport,
} from '@syncfusion/ej2-vue-grids';
Vue.use(GridPlugin);
export default {
    components: {
        EditFilterModal: () => import('../Modals/EditFilterModal.vue'),
    },
    provide: {
        grid: [
            Sort,
            Page,
            Filter,
            Freeze,
            CommandColumn,
            Search,
            Toolbar,
            Grid,
            Resize,
            ExcelExport,
        ],
    },
    data() {
        return {
            editCommands: [
                {
                    type: 'Edit',
                    buttonOption: {
                        cssClass: 'e-flat',
                        iconCss: 'e-edit e-icons',
                    },
                },
            ],
            savedTableData: [],
            pageSettings: {
                pageSize: 15,
                pageSizes: [12, 50, 100, 200],
                pageCount: 4,
            },
            selectedUserPortalOptionID: null,
            selectedRow: null,
            dataSetId: null,
            filterName: '',
            filterDescription: '',
            filterCategory: '',
            editUserPortalId: 0,
        };
    },
    methods: {
        loadHistory() {
            let tempGridFilters = {
                dataSetId: parseInt(this.dataSetId),
                projectId: parseInt(this.ProjectID),
                selectedFilters: [...this.selectedRow.gridFilters],
            };

            this.setCurrentGridFilter(tempGridFilters);
            //then set the tool to data and load the correct data set grid
            this.$emit('navigateToDataTool');
            datasetService
                .updateSavedWork(this.selectedUserPortalOptionID)
                .then((res) => {})
                .catch((err) => {});
        },
        hideReviewModal() {},
        dataSetRowSelected(args) {
            this.selectedUserPortalOptionID = args.data.UserPortalOptionID;
            this.dataSetId = args.data.DatasetId;
            this.selectedRow = this.createArgs(args);
        },
        gridRowData(args) {
            this.filterName = args.rowData.Name;
            this.filterDescription = args.rowData.Description;
            this.filterCategory = args.rowData.Category;
            this.editUserPortalId = args.rowData.UserPortalOptionID;
        },
        loadTable() {
            datasetService
                .getSavedWork(this.ProjectID)
                .then((res) => {
                    res.data.forEach((itm) => {
                        itm.CreationDate = this.getUTCDate(itm.CreationDate);
                        if (itm.DateLastUsed) {
                            itm.DateLastUsed = this.getUTCDate(
                                itm.DateLastUsed
                            );
                        }
                    });
                    this.savedTableData = res.data.sort((a, b) => {
                        return (
                            new Date(b.CreationDate) - new Date(a.CreationDate)
                        );
                    });
                })
                .catch((err) => {});
        },
        getUTCDate(dateParm) {
            var stillUtc = moment.utc(dateParm).toDate();
            return (
                moment(stillUtc)
                    .local()
                    .format('MM/DD/yyyy') +
                ' ' +
                moment(stillUtc)
                    .local()
                    .format('LT')
            );
        },
        createArgs(args) {
            let availableFilterArr = [];
            let gridFilters = [];
            args.data.DatasetFilter.forEach((element) => {
                if (!element.isGridFilter) {
                    let tempObj = {
                        ColumnName: element.ColumnName,
                        filterCondition: element.FilterCondition
                            ? element.FilterCondition
                            : null,
                        filterValue: element.FilterValue
                            ? element.FilterValue
                            : null,
                    };
                    availableFilterArr.push(tempObj);
                } else {
                    let tempObj = {};
                    if (element.GridFilterType === 'Menu') {
                        tempObj = {
                            field: element.ColumnName,
                            operator: element.FilterCondition,
                            predicate: element.FilterPredicate,
                            value: element.FilterValue,
                            filterType: element.GridFilterType,
                        };
                        gridFilters.push(tempObj);
                    } else {
                        let TempValueArray = element.ColumnNameValues.split(
                            '~~'
                        );
                        TempValueArray.forEach((col) => {
                            tempObj = {
                                field: element.ColumnName,
                                operator: element.FilterCondition,
                                predicate: element.FilterPredicate,
                                value: col,
                                filterType: element.GridFilterType,
                            };
                            gridFilters.push(tempObj);
                        });
                    }
                }
            });
            return {
                //availableFilterArr,
                gridFilters,
            };
        },
        ...mapActions('gridFilters', {
            setCurrentGridFilter: 'setCurrentGridFilter',
        }),
        deleteEditFilterDetails() {
            this.filterName = '';
            this.filterDescription = '';
            this.filterCategory = '';
            this.editUserPortalId = 0;
            this.loadTable();
        },
        removeHistory() {
            datasetService
                .deleteSaveWork(this.selectedUserPortalOptionID)
                .then((res) => {
                    this.loadTable();
                })
                .catch((err) => {});
        },
    },
    mounted() {
        this.loadTable();
    },
    computed: {
        ...mapGetters('projects', {
            ProjectID: 'ProjectID',
        }),
    },
    activated() {
        this.loadTable();
    },
};
</script>

<style>
@import url(https://cdn.syncfusion.com/ej2/material.css);
.load-filter-btn-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
</style>
